import { Component, OnInit } from '@angular/core';
import {User} from '../../user';
import {CategoriesService} from "../../companies/services/categories.service";
import {AuthService} from "../../auth/auth.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public user: User = new User;
  public options: any = [];
  public loading: Boolean = false;
  public isSent: Boolean = false;

  constructor(private categoriesService: CategoriesService, private authService: AuthService, private toastr: ToastrService, private router: Router) { }

  ngOnInit() {
    this.categoriesService.getSectors().subscribe((data) => {
      this.options = data;
      this.user.sector = data[0].title;
    })
  }

  register() {
    this.loading = true;

    const selectedSectorItem = this.options.filter((item) => {
      return item.title === this.user.sector;
    });

    this.authService.register(this.user.email, this.user.password, 'maestro', this.user.firstname, this.user.lastname, this.user.company, selectedSectorItem[0].id, this.user.position).subscribe((response) => {
      this.loading = false;
      this.isSent = true;
      this.toastr.success('Ihre Registrierung ist bei uns eingegangen. Sie werden benachrichtigt, sobald Ihr Antrag bearbeitet wurde.', 'Registrierung eingegangen');
    }, err => {
      this.loading = false;
      this.toastr.error('Bitte versuchen Sie es erneut. Prüfen Sie bitte auch, ob Sie bereits registriert sind.', 'Registrierung fehlgeschlagen');
    })

  }
}
