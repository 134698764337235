export class Company {
  title: string;
  postal: string;
  city: string;
  address: string;
  offer: String = '';
  searching: String = '';
  alias: string;
  sectorString: string;
  additionalSector: string;
  logopath: string;
  year: string;
  employes: string;
  website: string;
  username: string;
  firstname: string;
  lastname: string;
  position: string;
  phone: string;
  avatar: string;
  companyImg1: string;
  companyImg2: string;
  companyImg3: string;
  companyImg4: string;
  companyImg1Caption: string;
  companyImg2Caption: string;
  companyImg3Caption: string;
  companyImg4Caption: string;
  services: string;
  servicesImg1: string;
  servicesImg2: string;
  servicesImg3: string;
  servicesImg4: string;
  servicesImg1Caption: string;
  servicesImg2Caption: string;
  servicesImg3Caption: string;
  servicesImg4Caption: string;
  referencesText: string;
  referencesImg1: string;
  referencesImg2: string;
  referencesImg3: string;
  referencesImg4: string;
  referencesImg1Caption: string;
  referencesImg2Caption: string;
  referencesImg3Caption: string;
  referencesImg4Caption: string;
  admission: string;
  tags: string;


  constructor () {
  }
}
