import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  public url = 'https://matexapi.wfghn.de/api/2/';

  constructor(private http: HttpClient) {}

  getCompanies() {
    return this.http.get(this.url + 'companies');
  }

  getCompany(alias) {
    return this.http.get(this.url + 'companies/' + alias);
  }

  getImage(alias, field) {
    return this.http.get(this.url + 'companies/' + alias + '/' + field);
  }

  postMessage(message) {
    return this.http.post(this.url + 'companies/sendMessage', message);
  }

  postVcard(vcardContent) {
    return this.http.post(this.url + 'companies/generateVcard', vcardContent);
  }

  editCompany(company, area) {
    return this.http.put(this.url + 'companies/' + company.alias + '/' + area, {company: company}) ;
  }

  uploadImage(files, alias) {
    return this.http.post(this.url + 'companies/' + alias + '/upload', files);
  }
  deleteImage(area, alias, filename) {
    return this.http.post(this.url + 'companies/' + alias + '/deleteImage', {dbRowTitle: area, filename: filename}) ;

  }
}

