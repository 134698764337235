import { Component, OnInit } from '@angular/core';
import {log} from 'util';
import {CompaniesService} from './services/companies.service';
import {Company} from './company';
import {AppGlobals} from '../misc/globals';
import {CategoriesService} from './services/categories.service';
import * as orderConstant from '../../assets/constants/orderFilter.constant.js';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RouterServiceService} from '../services/router-service.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  truncateVal: any = 100;

  public loaded = false;
  public showSectorsFilter: Boolean = false;
  public showTypeFilter: Boolean = false;
  public showOrderBy: Boolean = false;

  public companies: Array<Company> = [];
  public filterLength;
  public searchText: string;
  public orderByObject: any = orderConstant.options;

  public currentOrder: any = this.orderByObject[0];

  public order: String = 'title';
  public filter: any = {

    sectors: [],
    type: [
      {
        title: 'Suche',
        state: false
      },
      {
        title: 'Biete',
        state: false
      }
    ],
    count: 0
  };


  constructor(private companiesService: CompaniesService,
              private categoriesService: CategoriesService,
              private _global: AppGlobals,
              private route: ActivatedRoute,
              private router: Router) {
    }

  ngOnInit() {
    this.init();
  }

  init () {
    this.companiesService.getCompanies().subscribe((companies: Array<Company>) => {
      this.companies = companies;
      this.loaded = true;

      this.categoriesService.getSectors().subscribe((categories) => {
        this.filter.sectors = categories;

        this.route.queryParams.subscribe(params => {
          if (params.branche) {

            const array = params.branche.split(',');
            array.forEach((value) => {
              this.filter.sectors.forEach((sector) => {
                this.filterLength = 1;
                if (sector.title === value) {
                  sector.state = true;

                }
              });
            });
            this.updateCompanyList();
          }

          if (params.typ) {
            const array = params.typ.split(',');
            array.forEach((value) => {
              this.filter.type.forEach((type) => {

                if (type.title === value) {
                  type.state = true;

                }
              });
            });
            this.updateCompanyList();
          }

          if (params.sortierung) {
            this.currentOrder = this.orderByObject[params.sortierung];
          }
        });

      });

    }, () => {
      this.companies = [];
    });
  }

  checkHighlighting = function (text) {

    if (this.searchText !== undefined && this.searchText !== '') {
      if (text.toLowerCase().match('^.*' + this.searchText.toLowerCase() + '.*$')) {
        return true;
      }
    }

    return false;
  };

  checkFilter = function (company) {
    const obj = company.sectorString.split(',');
    return true;
  };

  resetFilter = function() {

    this.filter.sectors.forEach((sector) => {
        sector.state = false;
    });

    this.updateCompanyList();
  }

  updateCompanyList = function () {

    const sectors = this.getSectorFilter();

    this.setParams();



    this.companies.forEach(company => {
      company.active = true;

      // Sectors
      sectors.forEach(sector => {

        if (!company.sectorString.includes(sector)) {
          company.active = false;
        }
      });

      // Type
      if ((company.searching === null || company.searching === '') && this.filter.type[0].state === true) {
        company.active = false;
      }

      if ((company.offer === null || company.offer === '') && this.filter.type[1].state === true) {
        company.active = false;
      }

    });

    this.filterLength = this.companies.filter((x, i) => {
      return x.active;
    }).length;


    // Count active filter
    this.filter.count = this.filter.sectors.filter((x, i) => {
      return x.state;
    }).length;

    this.filter.count += this.filter.type.filter((x, i) => {
      return x.state;
    }).length;

  };

  updateSortParams = function (currentOrder) {
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['sortierung'] = currentOrder.id;

    this.router.navigate(['/unternehmen'], { queryParams: queryParams });

  };

  private getSectorFilter = function () {
    const sectors = [];


    Object.keys(this.filter.sectors).forEach((key) => {
      if (this.filter.sectors[key].state === true) {
        sectors.push(this.filter.sectors[key].title);
      }
    });
    return sectors;
  };

  private setParams = function () {

    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);

    if (this.getSectorFilter().length > 0) {
      queryParams['branche'] = this.getSectorFilter().toString();
      this.router.navigate(['/unternehmen'], { queryParams: queryParams });
    } else {
      queryParams['branche'] = undefined;
      this.router.navigate(['/unternehmen'], { queryParams: queryParams });
    }


    const typeFilter = [];
    this.filter.type.forEach(filter => {
      if (filter.state === true) {
        typeFilter.push(filter.title);
      }
    });

    if (typeFilter.length > 0) {
      queryParams['typ'] = typeFilter.toString();
      this.router.navigate(['/unternehmen'], { queryParams: queryParams });
    } else {
      queryParams['typ'] = undefined;
      this.router.navigate(['/unternehmen'], { queryParams: queryParams });
    }
  };

}
